
:root {
  --fg-color: #282c34;
  --bg-color: white;
  --link-color: deepskyblue;
  --criteria-bg-color:rgba(200, 200, 200, 0.5);
  --disabled-color: #808080;
}

@media (prefers-color-scheme: dark) {
  :root {
    --fg-color: white;
    --bg-color: #282c34;
    --link-color: skyblue;
    --criteria-bg-color:rgba(100, 100, 100, 0.5);
  }
}

body {
  font-size: calc(12px + 2vmin);
}

.eligible {
  border-style: dashed;
  border-color: var(--link-color);
  border-width: 0 5px 0 5px;
}

#App {
  text-align: center;
}

.App-header {
  background-color: var(--bg-color);
  color: var(--fg-color);
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  align-items: center;
  justify-content: center;
  display: inline-block;
  /*display: flex;
  flex-direction: column;*/
}


a {
  color: var(--link-color);
  text-decoration: unset;
}
a:hover {
  text-decoration: underline;
}
h3.title a {
  color: unset;
}

div.nav {
  border-color: var(--fg-color);
  border-width: 2px 0;
  border-style: solid;
}
div.nav ul {
  padding-inline-start: 0;
}
div.nav ul li {
  display: inline;
  margin: 1em;
}

blockquote {
  margin-inline-start: 1em;
  margin-inline-end: 1em;
}



div.list {
  font-size: 0.9em;
}

div.list ul {
  list-style: none;
  padding-inline-start: 0;
}
div.list ul li {
  text-align: left;
  margin: 3em 0;
}
div.list ul li ul {
  font-size: 0.8em;
}
div.list ul li ul li {
  display: inline;
  margin: 0.5em;
}

div.search div.criteria {
  background: var(--criteria-bg-color);
  display: inline-block;
  padding: 0 0.5em 0.25em 0.5em;
  margin: 0;
  border-radius: 0 0 0.2em 0.2em;
}

div.article {
  font-size: 0.6em;
  text-align: left;
  margin: 3em;
  max-width: 50em;
}
div.article h3 {
  margin-bottom: 0;
  font-weight: 400;
}
div.article.hidden h3 {
  color: var(--link-color);
}
div.article div.tags ul {
  list-style: none;
  margin: 0;
  padding-inline-start: 0;
}
div.article div.tags ul li {
  display: inline;
  margin: 0 0.5em 0 0;
}
div.article div.month {
  font-size: 0.9em;
}
div.article div.more {
  text-align: left;
}
div.article span.edit {
  margin-left: 1em;
  font-size: 0.5em;
  text-transform: uppercase;
  padding: 0.1em 0.3em;
  border: 1px solid var(--link-color);
  border-radius: 0.25em;
  vertical-align: text-top;
}
div.article blockquote {
  margin: 1em 0;
  font-size: 0.9em;
}
div.article.hidden blockquote {
  color: var(--disabled-color);
}



form div.row {
  margin: 1em;
}
form div.row label span {
  margin-right: 0.25em;
  font-size: 0.75em;
  text-transform: lowercase;
  /*width: 3.5em;*/
  display: inline-block;
}
form div.row label span.tags {
  vertical-align: top;
  padding-top: 1.8em
}
form div.row ul.tags {
  list-style: none;
  display: inline-block;
  /*width: 70%;*/
  max-width: 15em;
  padding-inline-start: 0;
}
form div.row ul.tags li {
  text-align: left;
  margin: 0 0.2em;
  display: inline-block;
  float: right;
}
form div.row ul.tags li.addNew {
  padding: 0.5em;
  padding-top: 0;
  border-width: 1px;
  border-style: dotted;
  float: left;
}
form div.row ul.tags li button {
  margin: 0;
}
form div.row ul.tags li button#newTagBtn {
  margin: 0;
}
form div.row ul.tags li input {
  margin: 0;
  width: 5em !important;
}

form.articleEdit {
  width: 700px;
  margin-bottom: 9em;
}
form.articleEdit div.row input[type="text"] {
  width: 25em;
}
form.articleEdit div.row input[type="submit"] {
  font-size: 0.8em;
  padding: 0.5em 0.75em;
}
form.articleEdit div.row {
  text-align: left;
  max-width: 35em;
}
form.articleEdit div.row.editor {
  height: 600px;
}
form.articleEdit div.row.editor div.quill {
  height: 500px;
}

form.login div.row {
  text-align: right;
}



@media only screen and (max-width: 600px) {
  body {
    font-size: calc(14px + 2vmin);
  }
  div.article {
    font-size: 0.9em;
  }
}
